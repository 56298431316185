<script setup lang="ts">
const { t } = useI18n();

useSeoMeta({
  description: t('head.description'),
  ogDescription: t('head.description'),
  keywords: t('head.keywords'),
  author: 'VVeb Developer',
  ogType: 'website',
  ogLocale: 'ru_RU',
  ogImage: absoluteUrl(`/images/belarusborder.png`),
});
</script>

<template>
  <div class="container py-2 px-sm-4 py-sm-4">
    <AppNavbar />
    <slot />
    <AppToaster />
  </div>
</template>