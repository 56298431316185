<script setup lang="ts">
const { telegram: { bot_link } } = useAppConfig();
</script>

<template>
  <nav class="navbar bg-body-secondary mb-2">
    <div class="container-fluid flex-column flex-md-row align-items-stretch align-items-md-center">
      <div class="d-flex">
        <NuxtLink
          to="/"
          class="navbar-brand"
        >
          <img
            src="/images/navbar.png"
            height="32"
            class="d-inline-block align-top"
            :alt="$t('navbar.main')"
          > {{ $t('navbar.main') }}
        </NuxtLink>
        <a
          v-if="bot_link"
          v-tooltip="$t('navbar.telegram_bot_link_tooltip')"
          class="icon-link link-dark link-underline link-underline-opacity-0 ms-auto"
          :href="bot_link"
        >
          <i class="icon icon-xl cib-telegram-plane" />
        </a>
      </div>
      <div class="d-flex flex-column flex-md-row">
        <ClientOnly>
          <NavbarTrackingForm />
          <template #fallback>
            <span
              class="spinner-border spinner-border-sm text-success m-auto"
              aria-hidden="true"
            />
          </template>
        </ClientOnly>
      </div>
    </div>
  </nav>
  <ClientOnly>
    <NavbarTrackingSessions />
  </ClientOnly>
</template>
