<script setup lang="ts">
import QueueItem from "~/dto/queue/QueueItem";

const { t } = useI18n();

defineProps({
  item: {
    type: Object as () => QueueItem,
    required: true,
  },
  iconSize: {
    type: String,
    default: 'icon-lg',
  }
});
</script>

<template>
  <div v-tooltip="t('navbar.tracking.modal.arrived_time_title', {time: item.registeredAt.toFormat('HH:mm, dd MMM')})">
    <i
      class="cil-input icon"
      :class="iconSize"
    /> {{ item.registeredAt.toFormat('HH:mm, dd MMM') }}
  </div>
</template>
