<script setup lang="ts">
import Checkpoint from "~/dto/Checkpoint";

const { t } = useI18n();

defineProps({
  checkpoint: {
    type: Object as () => Checkpoint,
    required: true
  },
  iconSize: {
    type: String,
    default: 'icon-lg',
  }
});
</script>

<template>
  <div
    v-tooltip="t('navbar.tracking.checkpoint')"
    class="d-flex align-items-center"
  >
    <i
      class="cil-location-pin icon"
      :class="iconSize"
    /> {{ checkpoint.name }}
  </div>
</template>
