<script setup lang="ts">

const store = useTrackingStore();
const toasts = useToastsStore();
const {isLookingNumber, isLoadingGuestTrackingSession, trackingSessions} = storeToRefs(store);

const registrationNumberValue = ref<string>('');
const isSearchDisabled = computed(() => registrationNumberValue.value.length <= 2 || isLookingNumber.value);

const { t } = useI18n();
const analytics = useAnalytics();

const find = () => {
  analytics.trackingUsing(registrationNumberValue.value);
  store.startTracking(registrationNumberValue.value)
      .then(session => {
        if (session) {
          analytics.trackingFound(registrationNumberValue.value);
        } else {
          toasts.addInfo(t('navbar.tracking.no_car_found'));
          analytics.trackingNotFound(registrationNumberValue.value);
        }
      })
      .catch((error) => {
        analytics.trackingFailed(registrationNumberValue.value);
        toasts.addError(t('error_occurred_try_later'));
        captureException(error);
      });
};
</script>

<template>
  <div
    v-if="isLoadingGuestTrackingSession"
    class="d-flex"
  >
    <span
      class="spinner-border spinner-border-sm text-success"
      aria-hidden="true"
    />
  </div>
  <div
    v-else-if="0 === trackingSessions.length"
    class="d-flex"
  >
    <input
      v-model="registrationNumberValue"
      type="search"
      class="form-control me-2"
      maxlength="10"
      :placeholder="t('navbar.tracking.car_plate')"
      :disabled="isLookingNumber"
      @keyup.enter="find"
    >
    <button
      type="button"
      class="btn btn-outline-secondary d-inline-flex align-items-center"
      :disabled="isSearchDisabled"
      @click="find"
    >
      <span
        v-if="isLookingNumber"
        class="spinner-border spinner-border-sm me-1"
        aria-hidden="true"
      />
      {{ t('navbar.tracking.find') }}
    </button>
  </div>
</template>
