<script setup lang="ts">
import QueueItem from "~/dto/queue/QueueItem";

const { t } = useI18n();

defineProps({
  item: {
    type: Object as () => QueueItem,
    default: null,
  }
});

const el = ref();

const emit = defineEmits(['close']);

onMounted(() => {
  (new (useBootstrap().Modal)(el.value)).show();
  el.value.addEventListener('hidden.bs.modal', () => {
    emit('close');
  });
});
</script>

<template>
  <div
    ref="el"
    class="modal fade"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ t('navbar.tracking.modal.title', {number: item.registrationNumber}) }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <div class="modal-body">
          <div
            v-if="item"
            class="text-center"
          >
            <div v-if="item.isArrived">
              <h2>{{ t('navbar.tracking.modal.queue_position') }}</h2>
              <div class="queue-position">
                {{ item.callingOrder }}
              </div>
            </div>
            <div
              v-else
              class="fs-1"
            >
              {{ t('navbar.tracking.modal.queue_called') }}
            </div>
            <div class="data fs-2">
              <div>
                <div class="float-start">
                  <NavbarTrackingCheckpoint
                    :checkpoint="item.checkpoint!"
                    icon-size="icon-custom-size"
                  />
                </div>
                <div class="float-end">
                  <NavbarTrackingWaitingTime
                    :item="item"
                    icon-size="icon-custom-size"
                  />
                </div>
              </div>
              <div class="clearfix" />
              <div class="text-center">
                <NavbarTrackingArrivedTime
                  :item="item"
                  icon-size="icon-custom-size"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.queue-position {
  font-size: 10rem;
  line-height: 8rem;
}

.data :deep(.icon-custom-size) {
  font-size: calc(1.3rem + 0.8vw);
}
</style>