<script setup lang="ts">
import type TrackingSession from "~/dto/queue/TrackingSession";
import QueueItem from "~/dto/queue/QueueItem";

const {t} = useI18n();

const props = defineProps({
  session: {
    type: Object as () => TrackingSession,
    required: true
  }
});

const store = useTrackingStore();

const item = computed<QueueItem>(() => props.session.item);

const close = () => {
  store.stopTracking(props.session);
}

defineEmits(['openModal']);
</script>

<template>
  <div class="tracking-session border rounded p-2 position-relative bg-body">
    <a
      class="icon-link position-absolute end-0 me-2"
      href="#"
      @click="close"
    >
      <button class="btn btn-close" />
    </a>

    <div class="row">
      <div class="col-5 ps-3">
        <div
          v-if="item.isArrived"
          class="calling-order d-inline-block h1"
          @click="$emit('openModal', session)"
        >
          <strong>{{ item.callingOrder }}</strong>
        </div>
        <div
          v-else
          class="h2 called"
        >
          {{ t('navbar.tracking.queue_called') }}
        </div>
        <div>
          <div
            class="registration-number h3 d-inline-block border border-2 border-dark rounded px-1 m-0"
            @click="$emit('openModal', session)"
          >
            <strong>{{ item.registrationNumber }}</strong>
          </div>
        </div>
      </div>
      <div class="col-7 text-nowrap">
        <div>
          <div
            v-tooltip="t('navbar.tracking.checkpoint')"
            class="h4 d-inline-block mt-1 mb-1"
          >
            <strong>{{ item.checkpoint!.name }}</strong>
          </div>
        </div>
        <div>
          <NavbarTrackingWaitingTime
            :item="item"
            class="d-flex align-items-center h5 mb-1"
          />
        </div>
        <div
          v-tooltip="t('navbar.tracking.arrived_time_title')"
          class="d-flex align-items-center h5 m-0"
        >
          <i class="cil-input icon icon-lg me-1" /> {{ item.registeredAt.toFormat('HH:mm, dd MMM') }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.tracking-session {
  padding-bottom: 0.75rem !important;

  .btn-close:hover {
    background-color: #b39f9f;
  }

  .registration-number {
    font-size: 1.4rem;
    line-height: 1.4rem;
    cursor: pointer;
  }

  .calling-order {
    font-size: 2.2rem;
    line-height: 2.4rem;
    margin-bottom: 0.2rem;
    cursor: pointer;
  }

  .called {
    font-size: 1.8rem;
    line-height: 2.4rem;
    margin-top: 0;
    margin-bottom: 0;
  }

  .row {
    .col-12 > div {
      padding-right: 2rem;
    }
  }
}
</style>